<template>
  <div>
    <b-card>
      <div class="app_title_box">
        <h4>Services List</h4>
        <!-- <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="outline-primary"
          @click="showModal"
        >
          New
        </b-button> -->
      </div>
      <div class="mb-2">
        <b-row>
          <b-col cols="3">
            <b-form-group
              label="Search by keyword"
              label-for="v-search-keyword"
            >
              <b-form-input
                id="h-search-keyword"
                v-model="filter"
                type="text"
                placeholder="search here..."
              />
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-table
        responsive="sm"
        :items="services"
        :fields="fields"
        sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :filter="filter"
        :filter-included-fields="filterOn"
        :per-page="perPage"
        :current-page="currentPage"
        @row-clicked="showDetails"
      />
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        align="center"
        size="lg"
        class="my-0"
      />
    </b-card>

    <b-modal
      id="app-modal"
      :title="is_update ? 'Edit' : 'Add New'"
      hide-footer
      @hide="hide"
    >
      <b-card-text>
        <validation-observer ref="tagForm">
          <b-form @submit.prevent="is_update ? updateTag() : createTag()">
            <b-row>
              <!-- first name -->
              <b-col cols="12">
                <b-form-group
                  label="Name"
                  label-for="v-name"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="name"
                    rules="required"
                  >
                    <b-form-input
                      id="v-name"
                      v-model="service.name"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

            </b-row>
            <div class="mt-4 d-flex align-items-center justify-content-between">
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-secondary"
                @click="hide"
              >
                Cancel
              </b-button>

              <b-button
                v-if="is_update"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-danger"
                @click="deleteData"
              >
                Delete
              </b-button>

              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="outline-success"
                type="submit"
              >
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard, BButton, BModal, VBModal, BCardText, BTable, BRow, BCol, BFormGroup, BFormInput, BForm, BFormSelect, BPagination,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BButton,
    BModal,
    BCardText,
    BTable,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BPagination,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  data() {
    return {
      is_update: false,
      filter: null,
      sortBy: 'id',
      sortDesc: false,
      filterOn: [],
      fields: [{ key: 'name', label: 'name', sortable: true }],
      service: { name: '' },
      required,
      perPage: 10,
      pageOptions: [3, 5, 10],
      currentPage: 1,
    }
  },
  computed: {
    services() {
      return this.$store.state.serviceOffer.services.map(item => {
        const data = {
          id: item.service.id,
          name: item.service.name,
          category_id: item.category_id,
          business_profile_id: item.business_profile_id,
        }
        return data
      })
    },
    totalRows() {
      return this.services.length
    },
  },
  mounted() {
    this.getService()
  },
  methods: {
    getService() {
      return this.$store.dispatch('serviceOffer/getServices')
    },
    // createTag() {
    //   this.$refs.tagForm.validate().then(success => {
    //     if (success) {
    //       this.$store.dispatch('tag/createTag', this.tag)
    //       this.hide()
    //     }
    //   })
    // },
    updateTag() {
      this.$refs.tagForm.validate().then(success => {
        if (success) {
          this.$store.dispatch('serviceOffer/updateSerivceOffer', this.service)
          this.hide()
          const dom = this
          setTimeout(() => {
            dom.getService()
          }, 200)
        }
      })
    },
    emptyData() {
      this.service = { name: '' }
    },
    showModal() {
      this.$bvModal.show('app-modal')
    },
    hide() {
      this.is_update = false
      this.$bvModal.hide('app-modal')
      this.emptyData()
    },
    showDetails(item) {
      this.is_update = true
      this.service = item
      this.showModal()
    },
    deleteData() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serviceOffer/deleteSerivceOffer', this.service.id)
          const dom = this
          setTimeout(() => {
            dom.getService()
          }, 200)
          this.hide()
        }
      })
    },
  },
}
</script>

  <style>

  </style>
